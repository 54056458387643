import 'styles/global.css';
import { useEffect, useRef } from 'react';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import { getCookie } from 'cookies-next';
import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import { useRouter } from 'next/router';
import { Providers } from '~/components';
import { gtag, track } from '~/lib';

let qualifiedTimer;

gsap.registerPlugin(ScrollTrigger);

function MyApp({ Component, pageProps }) {
  const { locale, defaultLocale } = useRouter();
  const isTrackingQualified = useRef(false);

  const consent = getCookie('localConsent');

  const clearQualifiedTrackers = () => {
    if (qualifiedTimer) {
      clearTimeout(qualifiedTimer);
    }

    document.removeEventListener('mousemove', initGtm);
    document.removeEventListener('touchstart', initGtm);
    document.removeEventListener('scroll', initGtm);
  };

  const initGtm = () => {
    if (isTrackingQualified.current) {
      return;
    }

    isTrackingQualified.current = true;

    clearQualifiedTrackers();

    (window as any).dataLayer = (window as any).dataLayer || [];

    gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    });

    if (consent === true) {
      gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      track({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
        'gtm.uniqueEventId': 0,
      });
    };
    script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GTM}`;

    document.head.appendChild(script);
  };

  useEffect(() => {
    qualifiedTimer = setTimeout(initGtm, 3500);
    document.addEventListener('mousemove', initGtm, { passive: true });
    document.addEventListener('touchstart', initGtm, { passive: true });
    document.addEventListener('scroll', initGtm, { passive: true });

    return clearQualifiedTrackers;
  }, []);

  return (
    <Providers intl={{ locale, defaultLocale, messages: pageProps.messages }}>
      <Component {...pageProps} />
    </Providers>
  );
}

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponentProps: {
        buttonBackgroundColor: '#000',
        buttonColor: '#fff',
        logo: '/icon.svg',
      },
    })
  : MyApp;
