export const track = (event) => {
  try {
    (window as any).dataLayer.push(event);
  } catch (e) {
    console.error(e);
  }
};

export const gtag: (...args: any[]) => void = function () {
  try {
    (window as any).dataLayer.push(arguments);
  } catch (e) {
    console.error(e);
  }
};
