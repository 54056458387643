import { ReactNode, useRef, useState } from 'react';
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { StoryProvider } from '@storyofams/storyblok-toolkit';
import { IntlConfig, IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ParallaxProvider } from 'react-scroll-parallax';

type ProviderProps = {
  children: ReactNode;
  intl: IntlConfig;
};

export const Providers = ({ children, intl }: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  const [onboarding, setOnboarding] = useState<boolean>(false);

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <ParallaxProvider>
        <IntlProvider {...intl}>
          <TooltipProvider>
            <StoryProvider
              resolveRelations={[
                'newsletter_component.newsletter',
                'partner_page.banner',
                'page.banner',
                'integrations_page.banner',
                'careers_overview.banner',
                'vacancy_page.banner',
                'guide_index.banner',
                'guide_chapter.banner',
                'contact.banner',
                'home.banner_first',
                'home.banner_last',
                'blog_overview.banner_first',
                'blog_overview.banner_last',
                'blog_post.banner',
                'blog_post.author',
              ]}
              token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
            >
              {children}
            </StoryProvider>
          </TooltipProvider>
        </IntlProvider>
      </ParallaxProvider>
    </QueryClientProvider>
  );
};
